import React from 'react'
// import './specials.scss'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="contact page">
      <h1>Contact Us</h1>

      <h2>Address:</h2>
      <p><a href="https://www.google.com/maps/place/Two+Guys+From+Italy/@34.1519379,-118.2368943,15z/data=!4m2!3m1!1s0x0:0x678af46d2e7a9d35?ved=2ahUKEwj-8bih_ongAhVH7IMKHTWuCcoQ_BIwD3oECAYQCA">405 N Verdugo Rd.
      <br/>
      Glendale, CA 91206
    </a></p>

        <h2>Hours</h2>

        <p>Mon-Wed, 11am - 10pm</p>
        <p>Thu-Sat, 11am - 11pm</p>
        <p>Sun, 12pm - 10pm</p>

      <h2>Phone:</h2>
      <p><a href="tel:+18182400020">(818)240-0020</a></p>

      <h2>Email</h2>
      <p><a href="mailto:glendale2guys@afoodapart.com">glendale2guys@afoodapart.com</a></p>
    </div>
  )
}
