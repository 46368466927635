import React, { Component, PureComponent } from 'react';
import {NavLink, Link, Redirect, withRouter} from 'react-router-dom'
import './index.scss'
import Specials from './Specials'
import Catering from './Catering'
import Contact from './Contact'
import OurStory from './OurStory'


export const customOrderAppPaths = ["/menu", "/order"]


class TwoGuysFromItalyGlendale extends Component {
  render() {
    return (
      <div className="twoguysfromitalyglendale">
      </div>
    );
  }
}

export const pages = [
  {path: "/order", label: "Order"},
  {path: "/menu", label: "Menu" },
  {path: "/special", label: "Coupons", component: Specials},
  {path: "/catering", label: "Catering", component: Catering},
  {path: "/contact", label: "Contact", component: Contact},
  {path: "/about", label: "Our Story", component: OurStory},
  {path: "/menus", hide: true, component: () => (<Redirect to="/menu"></Redirect>)},
  {path: "/menus/:category", hide: true, component: () => (<Redirect to="/menu"></Redirect>)}
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, hide}) => (
          !hide ? <li><NavLink className="page-link" to={path}>{label}</NavLink></li>
                : null
        ))}
      </ul>
    </nav>
  )}
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="order-benefits-wrapper">
        <div className="order-benefits">

          <div
            className="text"
             >

            <p>Welcome to our direct ordering website.</p>
            <p>Start your order below.</p>
          </div>
        </div>
      </div>
    );
  }
}
// logo link: https://d2abh92eaqfsaa.cloudfront.net/image/upload/u7ryxhrj9dapgz8pqmny
class HeaderRaw extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">

        {/* <Redirect to="/order"></Redirect> */}

        <div className="sections-wrapper">
          <section className="section">
            <div className="content"
              style={{
                backgroundImage: `url(https://afag.imgix.net/two-guys-from-italy-glendale/meat-balls-pasta.jpg?w=800&auto=compress)`,

              }}
              >
                <div className="text">
                  <h1>Two Guys From Italy</h1>
                  <div className="button-wrapper">
                    <Link
                      className="order-online-button"
                      to="/order">Order Online
                    </Link>
                  </div>
                  <div className="receipt">
                    <br/>
                    <a href="mailto:2guysglendale@hidethellama.com">email us for issues/receipt</a>
                  </div>
                </div>

              </div>
          </section>

          <section className="section">
            <div className="content"
              style={{
                backgroundImage: `url(https://afag.imgix.net/two-guys-from-italy-glendale/pizza.jpg?w=800&auto=compress)`,

              }}
              >
              <div className="text">
                <h2>Menus</h2>
                <Link to="/menu">view menu</Link>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="content"
              style={{
                backgroundImage: `url(https://afag.imgix.net/two-guys-from-italy-glendale/porchiuto.jpg?w=800&auto=compress)`,

              }}
              >
              <div className="text">
                <h2>Catering</h2>
                <Link to="/catering">view catering</Link>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="content"
              style={{
                backgroundImage: `url(https://afag.imgix.net/two-guys-from-italy-glendale/interior.jpeg?w=800&auto=compress)`,

              }}
              >

              <div className="text">
                <h2>Visit Us</h2>
                <a href="https://www.google.com/maps/place/Two+Guys+From+Italy/@34.1519379,-118.2368943,15z/data=!4m2!3m1!1s0x0:0x678af46d2e7a9d35?ved=2ahUKEwj-8bih_ongAhVH7IMKHTWuCcoQ_BIwD3oECAYQCA">405 N Verdugo Rd.</a>
                <p>Mon-Wed, 11am - 10pm</p>
                <p>Thu-Sat, 11am - 11pm</p>
                <p>Sun, 12pm - 10pm</p>
                {/* <small><a href="tel:+18182400020">(818)240-0020</a></small> */}
              </div>
            </div>
          </section>
        </div>



        {/* <div className="order-benefits home">

          <div
            className="text"
             >
           <h1><img style={{width: "125px"}}
             src="https://d2abh92eaqfsaa.cloudfront.net/image/upload/u7ryxhrj9dapgz8pqmny" alt="Two Guys from Italy — Glendale"/></h1>
            <small>405 N Verdugo Rd Glendale, CA</small><br/>
            <small><a href="tel:+18182400020">(818)240-0020</a></small>

          </div>
        </div> */}
      </div>
    );
  }
}

export const Header = withRouter(HeaderRaw)

const foodImages = [
  'https://afag.imgix.net/two-guys-from-italy-glendale/meat-balls-pasta.jpg?w=800&auto=compress',
  'https://afag.imgix.net/two-guys-from-italy-glendale/porchiuto.jpg?w=800&auto=compress',
  'https://afag.imgix.net/two-guys-from-italy-glendale/pizza.jpg?w=800&auto=compress',
  'http://www.glendaletwoguysfromitaly.com/img/carousel/home_bg_d.jpg',
  'http://www.glendaletwoguysfromitaly.com/img/carousel/home_bg_e.jpg'
]

export default TwoGuysFromItalyGlendale;
