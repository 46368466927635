
import * as Components from "./Twoguysfromitalyglendale"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "twoguysfromitalyglendale"
}

