import React from 'react'
import './specials.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="specials-page">
      <AutoMenu categorysToShow={['yf3wWd6lmdxikZnWNEoB']} />

      <div className="full-menu-link-wrapper">
        <Link to="/order" className="button">Go to Full Menu</Link>
      </div>
    </div>
  )
}
